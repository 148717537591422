<template>
  <div class="container">
    <div class="content_box">
      <div class="title">新增会员周统计</div>
      <div id="echarts01" class="echarts" style="width: 1000px;height: 400px;"></div>
    </div>
    <div class="content_box">
      <div class="title">新增会员月统计</div>
      <div class="block">
        <span class="text">时间: </span>
        <el-date-picker v-model="yearMonth" type="month" value-format="yyyy-MM" placeholder="选择月" size="small">
        </el-date-picker>
        <el-button type="primary" size="small" @click="memberByMonth()">查询</el-button>
      </div>
      <div id="echarts02" class="echarts" style="width: 1000px;height: 400px;"></div>
    </div>
    <div class="content_box">
      <div class="title">新增会员年统计</div>
      <div class="block">
        <span class="text">时间: </span>
        <el-date-picker v-model="year" type="year" value-format="yyyy" placeholder="选择年" size="small">
        </el-date-picker>
        <el-button type="primary" size="small" @click="memberByYear()">查询</el-button>
      </div>
      <div id="echarts03" class="echarts" style="width: 1000px;height: 400px;"></div>
    </div>

  </div>
</template>

<script>
  let echarts = require('echarts');
  import {
    memberByYear,
    memberByMonth,
    memberByWeek
  } from '../../api/Statistics.js'
  export default {
    name: "memberStatistics",
    data() {
      return {
        yearMonth: '', // 月份
        year: '', // 年份
      }
    },
    created() {
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1;
      month = month < 10 ? '0' + month : month;
      this.year = year + ''
      this.yearMonth = year + '-' + month
      this.memberByWeek()
      this.memberByMonth()
      this.memberByYear()
    },
    methods: {
      // 会员周统计
      async memberByWeek() {
        const {
          data
        } = await memberByWeek()
        // 通过 echarts.init 方法初始化一个 echarts 实例并通过 setOption 方法生成
        let myChart = echarts.init(document.getElementById('echarts01'), 'macarons')
        let newData = data.data || []
        newData.forEach(item => {
          console.log('会员周统计数据回调遍历', item)
          // 指定图表的配置项和数据
          var option = {
            title: {
              // text: '会员周统计',
              // subtext: '订单统计'
            },
            tooltip: {
              trigger: 'axis'
            },
            grid: {
              left: 0
            },
            legend: {
              data: ['会员人数']
            },
            toolbox: {
              show: true,
              feature: {
                dataView: {
                  show: true,
                  readOnly: false
                },
                magicType: {
                  show: true,
                  type: ['line', 'bar']
                },
                restore: {
                  show: true
                },
                saveAsImage: {
                  show: true
                }
              }
            },
            calculable: true,
            xAxis: [{
              type: 'category',
              data: item.times.split(",")
            }],
            yAxis: [{
              type: 'value'
            }],
            series: [{
              name: '会员人数',
              type: 'line',
              data: item.timesNum.split(","),
              markPoint: {
                data: [{
                    type: 'max',
                    name: '最大值'
                  },
                  {
                    type: 'min',
                    name: '最小值'
                  }
                ]
              },
              markLine: {
                data: [{
                  type: 'average',
                  name: '平均值'
                }]
              }
            }]
          };
          myChart.setOption(option);
        })
      },

      // 会员月统计
      async memberByMonth() {
        const {
          data
        } = await memberByMonth({
          yearMonth: this.yearMonth
        })
        // 通过 echarts.init 方法初始化一个 echarts 实例并通过 setOption 方法生成
        let myChart = echarts.init(document.getElementById('echarts02'), 'macarons')
        let newData = data.data || []
        newData.forEach(item => {
          console.log('会员月统计数据回调遍历', item)
          // 指定图表的配置项和数据
          var option = {
            title: {
              // text: '时间：' + item.timesNum,
              // subtext: item.timesNum
            },
            tooltip: {
              trigger: 'axis'
            },
            grid: {
              left: 0
            },
            legend: {
              data: ['会员人数']
            },
            toolbox: {
              show: true,
              feature: {
                dataView: {
                  show: true,
                  readOnly: false
                },
                magicType: {
                  show: true,
                  type: ['line', 'bar']
                },
                restore: {
                  show: true
                },
                saveAsImage: {
                  show: true
                }
              }
            },
            calculable: true,
            xAxis: [{
              type: 'category',
              data: item.times.split(",")
            }],
            yAxis: [{
              type: 'value'
            }],
            series: [{
              name: '会员人数',
              type: 'line',
              data: item.userNum.split(","),
              markPoint: {
                data: [{
                    type: 'max',
                    name: '最大值'
                  },
                  {
                    type: 'min',
                    name: '最小值'
                  }
                ]
              },
              markLine: {
                data: [{
                  type: 'average',
                  name: '平均值'
                }]
              }
            }]
          };
          myChart.setOption(option);
        })
      },

      // 会员年统计
      async memberByYear() {
        const {
          data
        } = await memberByYear({
          year: this.year
        })
        // 通过 echarts.init 方法初始化一个 echarts 实例并通过 setOption 方法生成
        let myChart = echarts.init(document.getElementById('echarts03'), 'macarons')
        let newData = data.data || []
        newData.forEach(item => {
          console.log('会员年统计数据回调遍历', item)
          // 指定图表的配置项和数据
          var option = {
            title: {
              // text: '时间：' + item.timesNum,
              // subtext: item.timesNum
            },
            tooltip: {
              trigger: 'axis'
            },
            grid: {
              left: 0
            },
            legend: {
              data: ['会员人数']
            },
            toolbox: {
              show: true,
              feature: {
                dataView: {
                  show: true,
                  readOnly: false
                },
                magicType: {
                  show: true,
                  type: ['line', 'bar']
                },
                restore: {
                  show: true
                },
                saveAsImage: {
                  show: true
                }
              }
            },
            calculable: true,
            xAxis: [{
              type: 'category',
              data: item.times.split(",")
            }],
            yAxis: [{
              type: 'value'
            }],
            series: [{
              name: '会员人数',
              type: 'line',
              data: item.userNum.split(","),
              markPoint: {
                data: [{
                    type: 'max',
                    name: '最大值'
                  },
                  {
                    type: 'min',
                    name: '最小值'
                  }
                ]
              },
              markLine: {
                data: [{
                  type: 'average',
                  name: '平均值'
                }]
              }
            }]
          };
          myChart.setOption(option);
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  .container {
    padding: 0 20px;
    .content_box {
      color: #333333;
      line-height: 1.2;
      margin-bottom: 25px;

      .title {
        padding: 15px 0;
        font-size: 20px;
        font-weight: bold;
      }

      .block {
        font-size: 14px;

        .text {
          margin-right: 10px;
        }

        button {
          margin-left: 10px;
        }
      }

      .echarts {
        margin-top: 20px;
      }
    }
  }
</style>
